import Img from "gatsby-image";
import { useIntl } from "react-intl";
import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Form from "../../NewsletterForm";
import styles from "./styles";

const Footer: FunctionComponent = () => {
  const { locale, formatMessage } = useIntl();
  const { FooterWrapper, FooterLinks, FooterImg, FooterRights } = styles;

  const querydata = useStaticQuery(graphql`
    query {
      iso: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "logos/iso.png" }
      ) {
        childImageSharp {
          fixed(width: 85, height: 87) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prr: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "logos/prr.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rp: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "logos/rp.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eu: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "logos/eu.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pdfDocument: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "documents/ficha-projecto.pdf" }
      ) {
        publicURL
      }
    }
  `);

  return (
    <>
      <FooterWrapper>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <a
                href={querydata.pdfDocument.publicURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FooterImg>
                  <Img
                    imgStyle={{ objectFit: "contain" }}
                    fixed={querydata.iso.childImageSharp.fixed}
                    alt="iso9001 logo"
                  />
                </FooterImg>
              </a>
              <FooterLinks>
                <li style={{ marginRight: "10px" }}>
                  <AnchorLink
                    className="privacy-cookies"
                    to={`/${locale}/policies#briberypolicy`}
                  >
                    {formatMessage({ id: "anti-briberypolicy" })}
                  </AnchorLink>
                </li>
                <li className="separator"> | </li>
                <li style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <AnchorLink
                    className="privacy-cookies"
                    to={`/${locale}/policies#privacy`}
                  >
                    {formatMessage({ id: "privacy-policy" })}
                  </AnchorLink>
                </li>
                <li className="separator"> | </li>
                <li style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <AnchorLink
                    // bg="#f38f1d"

                    className="privacy-cookies"
                    to={`/${locale}/policies#cookies`}
                  >
                    {formatMessage({ id: "cookies-policy" })}
                  </AnchorLink>
                </li>
                <li className="separator"> | </li>
                <li style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <AnchorLink
                    className="privacy-cookies"
                    to={`/${locale}/policies#reportcommunicationpolicies`}
                  >
                    {formatMessage({ id: "reportcomm-policy" })}
                  </AnchorLink>
                </li>
                <li className="separator"> | </li>
                <li style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <AnchorLink
                    className="privacy-cookies"
                    to={`/${locale}/policies#reportcommunication`}
                  >
                    {formatMessage({ id: "report-communication" })}
                  </AnchorLink>
                </li>
                <li className="separator"> | </li>
                <li style={{ marginLeft: "10px" }}>
                  <AniLink
                    cover
                    bg="#f38f1d"
                    duration={1}
                    direction="top"
                    className="privacy-cookies"
                    to={`/${locale}/contact-us`}
                  >
                    {formatMessage({ id: "contact-us-top" })}
                  </AniLink>
                </li>
                <li className="separator"> | </li>
                <li style={{ marginLeft: "10px" }}>
                  <a
                    href={querydata.pdfDocument.publicURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="privacy-cookies"
                  >
                    {formatMessage({ id: "project-file" })}
                  </a>
                </li>
              </FooterLinks>
              <FooterRights>{formatMessage({ id: "rights" })}</FooterRights>
            </Col>
            <Col xs={12} md={6}>
              <Form footer />
            </Col>
          </Row>
        </Container>
      </FooterWrapper>
      <Row
        style={{
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0" // Add some padding if needed
        }}
      >
        <a
          href={querydata.pdfDocument.publicURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              display: "flex",
              gap: "3em",
              width: "26em"
            }}
          >
            <Img
              fluid={querydata.prr.childImageSharp.fluid}
              alt="PRR logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain"
              }}
            />
            <Img
              fluid={querydata.rp.childImageSharp.fluid}
              alt="RP logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain"
              }}
            />
            <Img
              fluid={querydata.eu.childImageSharp.fluid}
              alt="EU logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain"
              }}
            />
          </div>
        </a>
      </Row>
    </>
  );
};

export default Footer;
