import { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useIntl } from "react-intl";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import "../../cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import Cookies from "js-cookie";

import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

export default function CookieConsentComponent() {
  const { locale } = useIntl();
  const location = useLocation();

  useEffect(() => {
    CookieConsent.run({
      onConsent: () => {
        const consent = CookieConsent.validConsent();
        const analytics = CookieConsent.acceptedCategory("analytics");
        if (consent) {
          if (analytics) {
            Cookies.set("gatsby-gdpr-google-analytics", "true", {
              expires: 365,
            });
            Cookies.set("gatsby-gdpr-google-tagmanager", "true", {
              expires: 365,
            });
          } else {
            Cookies.remove("gatsby-gdpr-google-analytics");
            Cookies.remove("gatsby-gdpr-google-tagmanager");
          }
          initializeAndTrack(location);
        }
      },
      onChange: () => {
        const consent = CookieConsent.validConsent();
        const analytics = CookieConsent.acceptedCategory("analytics");
        if (consent) {
          if (analytics) {
            Cookies.set("gatsby-gdpr-google-analytics", "true", {
              expires: 365,
            });
            Cookies.set("gatsby-gdpr-google-tagmanager", "true", {
              expires: 365,
            });
          } else {
            Cookies.remove("gatsby-gdpr-google-analytics");
            Cookies.remove("gatsby-gdpr-google-tagmanager");
          }
          initializeAndTrack(location);
        }
      },

      guiOptions: {
        consentModal: {
          layout: "box inline",
          position: "bottom left",
        },
      },

      disablePageInteraction: true,

      categories: {
        necessary: {
          readOnly: true,
        },
        analytics: {
          enabled: true,
          autoClear: {
            cookies: [
              {
                name: /^(_fbp)/,
              },
              {
                name: /^(_ga)/,
              },
              {
                name: /^(_gat)/,
              },
              {
                name: /^(_gcl)/,
              },
              {
                name: "_gid",
              },
              {
                name: "_zft-sdc",
              },
              {
                name: "zps-tgr-dts",
              },
              {
                name: "zabUserId",
              },
              {
                name: "zabVisitId",
              },
              {
                name: "AnalyticsSyncHistory",
              },
              {
                name: "UserMatchHistory",
              },
              {
                name: "bcookie",
              },
              {
                name: "li_gc",
              },
              {
                name: "_iamadt",
              },
              {
                name: "_iambdt",
              },
              {
                name: "wms-tkp-token",
              },
              {
                name: "wms.agent",
              },
            ],
            reloadPage: true,
          },
        },
      },

      language: {
        default: `${locale}`,
        translations: {
          en: {
            consentModal: {
              title: "We use cookies!",
              description:
                "Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type='button' data-cc='show-preferencesModal' class='cc-link'>Let me choose</button>",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
            },
            preferencesModal: {
              title: "Cookie preferences",
              savePreferencesBtn: "Save settings",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              closeIconLabel: "Close",
              sections: [
                {
                  title: "Cookie usage 🍪",
                  description: `We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a target="_blank" href="/${locale}/policies#privacy" class="cc-link">privacy policy</a>.`,
                },
                {
                  title: "Strictly necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly",
                  linkedCategory: "necessary",
                  cookieTable: {
                    headers: {
                      name: "Name",
                      domain: "Domain",
                      expiration: "Expiration",
                      description: "Description",
                    },
                    body: [
                      {
                        name: "Session Storage",
                        domain: "Decunify.com",
                        expiration: "Session",
                        description:
                          "Session cookies allow users to be recognized within a website so any page changes or item or data selection you do is remembered from page to page.",
                      },
                      {
                        name: "Local Storage Cookie",
                        domain: "Decunify.com",
                        expiration: "Storage",
                        description:
                          "LocalStorage is an implementation of the Storage Interface. It stores data with no expiration date, and gets cleared only through JavaScript, or clearing the Browser Cache",
                      },
                    ],
                  },
                },
                {
                  title: "Performance and Analytics cookies",
                  description:
                    "These cookies allow the website to remember the choices you have made in the past",
                  linkedCategory: "analytics",
                  cookieTable: {
                    headers: {
                      name: "Name",
                      domain: "Domain",
                      expiration: "Expiration",
                      description: "Description",
                    },
                    body: [
                      // list of all expected cookies
                      {
                        name: "_fbp",
                        domain: "facebook.com",
                        expiration: "3 months",
                        description:
                          "Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.",
                      },
                      {
                        name: "_ga",
                        domain: "google.com",
                        expiration: "2 years",
                        description:
                          "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.	",
                      },
                      {
                        name: "_gat",
                        domain: "google.com",
                        expiration: "2 years",
                        description:
                          "Used by Google Analytics to throttle request rate",
                      },
                      {
                        name: "_gid",
                        domain: "google.com",
                        expiration: "1 day",
                        description:
                          "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
                      },
                      {
                        name: "_zft-sdc",
                        domain: "Matific.com",
                        expiration: "1 day",
                        description:
                          "Collects data on the user’s navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.",
                      },
                      {
                        name: "zps-tgr-dts",
                        domain: "Matific.com",
                        expiration: "1 day",
                        description:
                          "Determines whether the user is assigned to a specific content-experiment to optimize website content and advertisement efforts.	",
                      },
                      {
                        name: "zabUserId",
                        domain: "Matific.com",
                        expiration: "1 year",
                        description:
                          "Collects data on the user’s navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.	",
                      },
                      {
                        name: "zabVisitId",
                        domain: "Matific.com",
                        expiration: "1 year",
                        description:
                          "Collects data on the user’s navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.	",
                      },
                      {
                        name: "AnalyticsSyncHistory",
                        domain: "Linkedin.com",
                        expiration: "30 days",
                        description:
                          "Used in connection with data-synchronization with third-party analysis service.",
                      },
                      {
                        name: "UserMatchHistory",
                        domain: "Linkedin.com",
                        expiration: "30 days",
                        description:
                          "Used to track visitors on multiple websites, in order to present relevant advertisement based on the visitor`s preferences",
                      },
                      {
                        name: "bcookie",
                        domain: "Linkedin.com",
                        expiration: "2 years",
                        description:
                          "Used by the social networking service, LinkedIn, for tracking the use of embedded services.",
                      },
                      {
                        name: "li_gc",
                        domain: "Linkedin.com",
                        expiration: "1 year",
                        description:
                          "Used to store guest consent regarding the use of cookies for non-essential purposes",
                      },
                      {
                        name: "_iamadt, _iambdt",
                        domain: "Zoho.eu",
                        expiration: "Session",
                        description:
                          "Cookie used by Zoho for Session management",
                      },
                      {
                        name: "wms-tkp-token, wms.agent",
                        domain: "Zoho.eu",
                        expiration: "Session",
                        description:
                          "This cookie is installed by Zoho. This cookie is used to monitor the performance and behavior of the user when browsing our website so that we can customize the content of our pages to better meet the needs of our customers.",
                      },
                    ],
                  },
                },
              ],
            },
          },
          pt: {
            consentModal: {
              title: "Nós usamos cookies",
              description:
                "Este website usa cookies para melhorar a experiência do utilizador. Pode configurar a utilização dos cookies abaixo. As suas opções podem ser alteradas a qualquer momento. <button type='button' data-cc='show-preferencesModal' class='cc-link'>Mostrar detalhes</button>",
              acceptAllBtn: "Aceitar todos",
              acceptNecessaryBtn: "Rejeitar todos",
            },
            preferencesModal: {
              title: "Preferência de Cookies",
              savePreferencesBtn: "Guardar alterações",
              acceptAllBtn: "Aceitar todos",
              acceptNecessaryBtn: "Rejeitar todos",
              closeIconLabel: "Cancelar",
              sections: [
                {
                  title: "Utilização de Cookies 🍪",
                  description: `Este website usa cookies para melhorar a experiência do utilizador. Pode configurar a utilização dos cookies abaixo. As suas opções podem ser alteradas a qualquer momento. Para mais informação sobre a nossa política de privacidade e de cookies, por favor aceda a nossa página de <a target="_blank" href="/${locale}/policies#privacy">política de privacidade</a>.`,
                },
                {
                  title: "Cookies estritamente necessários",
                  description:
                    "Estes cookies são essenciais para o bom funcionamento do nosso website. Sem estes cookies, o site não funcionará de forma correcta",
                  linkedCategory: "necessary",
                  cookieTable: {
                    headers: {
                      name: "Nome",
                      domain: "Domínio",
                      expiration: "Expira em",
                      description: "Descrição",
                    },
                    body: [
                      {
                        name: "Armazenamento de Sessão",
                        domain: "Decunify.com",
                        expiration: "Sessão",
                        description:
                          "Este cookie é usado para identificar a sessão do utilizador no servidor. A sessão é uma área no servidor que pode ser usada para armazenar o estado da sessão entre as solicitações de http.",
                      },
                      {
                        name: "Armazenamento Local",
                        domain: "Decunify.com",
                        expiration: "Sessão",
                        description:
                          "Este cookie serve para guardar em cache o website de forma a aumentar a rapidez de resposta do website em futuras consultas",
                      },
                    ],
                  },
                },
                {
                  title: "Cookies de Performance e Analíticos",
                  description:
                    "Estes cookies permitem que o website se lembre das escolhas que o utilizador fez no passado",
                  linkedCategory: "analytics",
                  cookieTable: {
                    headers: {
                      name: "Nome",
                      domain: "Domínio",
                      expiration: "Expira em",
                      description: "Descrição",
                    },
                    body: [
                      // list of all expected cookies
                      {
                        name: "^_fbp",
                        domain: "facebook.com",
                        expiration: "3 meses",
                        description:
                          "Utilizado pelo Facebook para servir anúncios através de sistemas de leilões em tempo real.",
                        isRegex: "true",
                      },
                      {
                        name: "^_ga",
                        domain: "google.com",
                        expiration: "2 anos",
                        description:
                          "Este nome de cookie está associado ao Google Universal Analytics - que é uma atualização significativa para o serviço de análise mais comumente usado do Google. Este cookie é usado para distinguir usuários únicos, atribuindo um número gerado aleatoriamente como um identificador de cliente. Ele é incluído em cada solicitação de página em um site e usado para calcular os dados do visitante, da sessão e da campanha para os relatórios de análise dos sites.",
                        isRegex: "true",
                      },
                      {
                        name: "^_gat",
                        domain: "google.com",
                        expiration: "2 anos",
                        description:
                          "Usado pelo Google Analytics para controlar o volume de pedidos ao servidor",
                        isRegex: "true",
                      },
                      {
                        name: "_gid",
                        domain: "google.com",
                        expiration: "1 dia",
                        description:
                          "Este cookie é definido pelo Google Analytics. Ele armazena e atualiza um valor único para cada página visitada e é usado para contar e rastrear visualizações de página.",
                      },
                      {
                        name: "_zft-sdc",
                        domain: "Matific.com",
                        expiration: "Persistente",
                        description:
                          "Este cookie serve para registar dados sobre a navegação e o comportamento dos utilizadores no website. Estes dados servem para compilar relatórios estatísticos e mapear o comportamento do utilização pela Decunify.",
                      },
                      {
                        name: "zps-tgr-dts",
                        domain: "Matific.com",
                        expiration: "1 dia",
                        description:
                          "Este cookie serve para determinar se o utilizador é selecionado de forma aleatória para um teste de conteúdo específico com o intuito de optimizar o conteúdo do website.",
                      },
                      {
                        name: "zabUserId",
                        domain: "Matific.com",
                        expiration: "1 ano",
                        description:
                          "Este cookie serve para registar dados sobre a navegação e o comportamento dos utilizadores no website. Estes dados servem para compilar relatórios estatísticos e mapear o comportamento do utilização pela Decunify.",
                      },
                      {
                        name: "zabVisitId",
                        domain: "Matific.com",
                        expiration: "1 ano",
                        description:
                          "Este cookie serve para registar dados sobre a navegação e o comportamento dos utilizadores no website. Estes dados servem para compilar relatórios estatísticos e mapear o comportamento do utilização pela Decunify.",
                      },
                      {
                        name: "AnalyticsSyncHistory",
                        domain: "Linkedin.com",
                        expiration: "30 dias",
                        description:
                          "Usado para armazenar informações sobre o horário em que uma sincronização com o cookie lms_analytics ocorreu para usuários nos países designados	",
                      },
                      {
                        name: "UserMatchHistory",
                        domain: "Linkedin.com",
                        expiration: "30 dias",
                        description:
                          "Sincronização de códigos de anúncios do LinkedIn	",
                      },
                      {
                        name: "bcookie",
                        domain: "Linkedin.com",
                        expiration: "2 anos",
                        description:
                          "Cookie de identificador de navegador para identificar de forma exclusiva dispositivos que acessam o LinkedIn para detectar abusos na plataforma	",
                      },
                      {
                        name: "li_gc",
                        domain: "Linkedin.com",
                        expiration: "1 ano",
                        description:
                          "Usado para armazenar o consentimento de convidados em relação ao uso de cookies para fins não essenciais	",
                      },
                      {
                        name: "_iamadt, _iambdt",
                        domain: "Zoho.eu",
                        expiration: "Sessão",
                        description:
                          "Cookie utilizado pela Zoho para gestão de Sessão.",
                      },
                      {
                        name: "wms-tkp-token, wms.agent",
                        domain: "Zoho.eu",
                        expiration: "Sessão",
                        description:
                          "Este cookie é instalado pelo Zoho. Este cookie é usado para monitorizar o desempenho e o comportamento do utilizador ma navegação do nosso website para que possamos personalizar o conteúdo de nossas páginas por forma a atender melhor as necessidades dos nossos clientes.",
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return null;
}
