import React, {
  FunctionComponent,
  CSSProperties,
  useState,
  useEffect
} from "react";
import { useIntl } from "react-intl";
// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Visible } from "react-grid-system";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import SocialNav from "../SocialNav";

import styles from "./styles";
import styled from "@emotion/styled";

interface LogoQuery {
  logo: {
    data: {
      image: {
        url: string;
        alt: string;
      };
    };
  };
  logowhite: {
    publicURL: string;
  };
  trasitionAboutPT: {
    publicURL: string;
  };
  trasitionAboutEN: {
    publicURL: string;
  };
  trasitionWithPT: {
    publicURL: string;
  };
  trasitionWithEN: {
    publicURL: string;
  };
  trasitionContactsPT: {
    publicURL: string;
  };
  trasitionContactsEN: {
    publicURL: string;
  };
}

interface TopMenuProps {
  white?: boolean;
  opacity?: number;
  underline?: "about-us" | "with-us" | "contact-us";
}

const TopMenu: FunctionComponent<TopMenuProps> = ({
  white,
  opacity,
  underline
}) => {
  const { locale, formatMessage } = useIntl();
  const [displayMobileMenu, setDisplayMobileMenu] = useState<boolean>(false);
  const [localePickerTarget, setLocaleTarget] = useState<string>(
    `/${locale === "pt" ? "en" : "pt"}`
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const scrollTo = (anchor: string, offset: number) => {
    const element = document.querySelector(anchor);
    if (element) {
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementPosition =
        element.getBoundingClientRect().top - bodyRect + offset;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth"
      });
    }
  };

  const mobileMenuLinks = [
    {
      url: `/${locale}/#solutions`,
      label: formatMessage({ id: "solutions" }),
      page: "",
      anchor: "#solutions",
      offset: 95
    },
    {
      url: `/${locale}/#services`,
      label: formatMessage({ id: "services" }),
      page: "",
      anchor: "#services",
      offset: 0
    },
    {
      url: `/${locale}/#case-studies`,
      label: formatMessage({ id: "case-studies" }),
      page: "",
      anchor: "#case-studies",
      offset: 135
    },
    {
      url: `/${locale}/blog`,
      label: formatMessage({ id: "blog" }),
      page: "blog"
    },
    {
      url: `/${locale}/about-us`,
      label: formatMessage({ id: "about-us" }),
      page: "about-us"
    },
    {
      url: `/${locale}/with-us`,
      label: formatMessage({ id: "with-us" }),
      page: "with-us"
    },
    {
      url: `/${locale}/contact-us`,
      label: formatMessage({ id: "contact-us-top" }),
      page: "contact-us"
    }
  ];

  const headerLinkStyle: CSSProperties = {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textDecoration: "none",
    color: white ? "white" : "black"
  };

  //TODO: change styles
  const LanguageDropdownStyle = styled.div`
    position: relative;
    display: inline-block;

    button {
      background: none;
      border: none;
      cursor: pointer;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      color: ${white ? "white" : "black"};
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }

    ul {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0;
      margin: 5px 0 0;
      list-style: none;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      li {
      &:not(:last-child) {
              border-bottom: 1px solid #eee;
            }

            a {
              font-family: Roboto;
              font-size: 14px;
              text-decoration: none;
              color: black;
              display: block;
              padding: 10px 15px;
              transition: background-color 0.2s ease;

              &:hover {
                background-color: #f5f5f5;
              }
      }
    }
  `;

  const {
    Header,
    HeaderLogo,
    HeaderRow,
    HeaderLinks,
    MobileHeader,
    MobileBackground,
    MenuDivider
  } = styles;

  const LanguageDropdown: React.FC = () => {
    return (
      <LanguageDropdownStyle>
        <div>
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="header-link-underline"
          >
            {locale.toUpperCase()}
          </button>
          {isDropdownOpen && (
            <ul>
              {locale != "pt" && (
                <li>
                  <AniLink
                    cover
                    bg="#f38f1d"
                    duration={1}
                    direction="top"
                    to={locale === "pt" ? `/${locale}/pt` : localePickerTarget}
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    PT
                  </AniLink>
                </li>
              )}
              {locale != "en" && (
                <li>
                  <AniLink
                    cover
                    bg="#f38f1d"
                    duration={1}
                    direction="top"
                    to={locale === "en" ? `/${locale}/en` : localePickerTarget}
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    EN
                  </AniLink>
                </li>
              )}
              <li>
                <a
                  href="https://www.decunify.es/es"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  ES
                </a>
              </li>
            </ul>
          )}
        </div>
      </LanguageDropdownStyle>
    );
  };

  const data: LogoQuery = useStaticQuery(graphql`
    query {
      logo: prismicImage(data: { name: { eq: "Logo" } }) {
        data {
          name
          image {
            url
            alt
          }
        }
      }
      logowhite: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "logos/logo-white.png" }
      ) {
        publicURL
      }
      trasitionAboutPT: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "transitions/pt-about-us.png" }
      ) {
        publicURL
      }
      trasitionAboutEN: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "transitions/en-about-us.png" }
      ) {
        publicURL
      }
      trasitionWithPT: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "transitions/pt-with-us.png" }
      ) {
        publicURL
      }
      trasitionWithEN: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "transitions/en-with-us.png" }
      ) {
        publicURL
      }
      trasitionContactsPT: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "transitions/pt-contacts.png" }
      ) {
        publicURL
      }
      trasitionContactsEN: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "transitions/en-contacts.png" }
      ) {
        publicURL
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLocaleTarget(
        `/${locale === "pt" ? "en" : "pt"}` + location?.pathname.substring(3)
      );
    }
  }, []);

  const handleMenuClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    setDisplayMobileMenu(!displayMobileMenu);
  };

  if (displayMobileMenu) {
    return (
      <MobileHeader>
        <div style={{ width: "15.7%" }}>
          <a onClick={handleMenuClick} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon
              size="2x"
              icon={faTimes}
              color="rgba(0, 0, 0, 0.6)"
              style={{ float: "right", marginRight: "12px" }}
            />
          </a>
        </div>
        <MobileBackground>
          {locale != "pt" && (
            <AniLink
              cover
              bg="#f38f1d"
              duration={1}
              direction="top"
              to={locale === "pt" ? `/${locale}/pt` : localePickerTarget}
              style={{
                ...headerLinkStyle,
                display: "block",
                marginBottom: "15px",
                textTransform: "uppercase"
              }}
            >
              PT
            </AniLink>
          )}
          {locale != "en" && (
            <AniLink
              cover
              bg="#f38f1d"
              duration={1}
              direction="top"
              to={locale === "en" ? `/${locale}/en` : localePickerTarget}
              style={{
                ...headerLinkStyle,
                display: "block",
                marginBottom: "15px",
                textTransform: "uppercase"
              }}
            >
              EN
            </AniLink>
          )}
          <a
            href="https://www.decunify.es/es"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              ...headerLinkStyle,
              display: "block",
              marginBottom: "45px",
              textTransform: "uppercase"
            }}
          >
            ES
          </a>
          {mobileMenuLinks.map((link, index) => (
            <>
              <AniLink
                cover
                bg="#f38f1d"
                to={link.url}
                duration={1}
                direction="top"
                key={`link#${index}`}
                style={{ ...headerLinkStyle, textTransform: "uppercase" }}
                onClick={() => {
                  if (
                    link.page ===
                      location.pathname.substring(
                        location.pathname.lastIndexOf("/") + 1
                      ) &&
                    link.anchor &&
                    (link.offset || link.offset === 0)
                  ) {
                    scrollTo(link.anchor, link.offset);
                  }
                  setDisplayMobileMenu(false);
                }}
              >
                {link.label}
              </AniLink>
              <MenuDivider />
            </>
          ))}
          <div style={{ marginTop: "7px", marginBottom: "22px" }}>
            <AniLink
              cover
              bg="#f38f1d"
              duration={1}
              direction="top"
              to={`/${locale}/policies#cookies`}
              style={headerLinkStyle}
              onClick={() => {
                if (
                  "policies" ===
                  location.pathname.substring(
                    location.pathname.lastIndexOf("/") + 1
                  )
                ) {
                  scrollTo("#cookies", 0);
                }
                setDisplayMobileMenu(false);
              }}
            >
              {formatMessage({ id: "cookies-policy" })}
            </AniLink>
          </div>
          <div style={{ marginTop: "22px", marginBottom: "171px" }}>
            <AniLink
              cover
              bg="#f38f1d"
              duration={1}
              direction="top"
              to={`/${locale}/policies#privacy`}
              style={headerLinkStyle}
              onClick={() => {
                if (
                  "policies" ===
                  location.pathname.substring(
                    location.pathname.lastIndexOf("/") + 1
                  )
                ) {
                  scrollTo("#privacy", 0);
                }
                setDisplayMobileMenu(false);
              }}
            >
              {formatMessage({ id: "privacy-policy" })}
            </AniLink>
          </div>
          <SocialNav />
        </MobileBackground>
      </MobileHeader>
    );
  }

  return (
    <Header opacity={opacity}>
      <Container>
        <Row>
          <Col xs={12}>
            <HeaderRow>
              <AniLink
                cover
                bg={`url(${data.logowhite.publicURL})
                center/25% no-repeat #f38f1d`}
                duration={1}
                direction="top"
                to={`/${locale}/`}
              >
                <HeaderLogo
                  src={data.logo.data.image.url}
                  alt={data.logo.data.image.alt || "Header Logo"}
                />
              </AniLink>
              <Visible xs sm>
                <a
                  onClick={handleMenuClick}
                  style={{ cursor: "pointer", alignSelf: "center" }}
                >
                  <FontAwesomeIcon
                    size="2x"
                    icon={faBars}
                    color="rgba(0, 0, 0, 0.6)"
                  />
                </a>
              </Visible>
              <Visible md lg xl>
                <HeaderLinks>
                  <li style={{ marginRight: "54px" }}>
                    <AniLink
                      cover
                      bg={`url(${
                        locale === "pt"
                          ? data.trasitionAboutPT.publicURL
                          : data.trasitionAboutEN.publicURL
                      })
                      center/25% no-repeat #f38f1d`}
                      duration={1}
                      direction="top"
                      to={`/${locale}/about-us`}
                      style={{
                        ...headerLinkStyle,
                        textTransform: "uppercase",
                        borderBottom:
                          underline === "about-us"
                            ? "4px solid #f38f1d"
                            : undefined
                      }}
                      className="header-link-underline"
                    >
                      {formatMessage({ id: "about-us" })}
                    </AniLink>
                  </li>
                  <li style={{ marginRight: "54px" }}>
                    <AniLink
                      cover
                      bg={`url(${
                        locale === "pt"
                          ? data.trasitionWithPT.publicURL
                          : data.trasitionWithEN.publicURL
                      })
                      center/25% no-repeat #f38f1d`}
                      duration={1}
                      direction="top"
                      to={`/${locale}/with-us`}
                      style={{
                        ...headerLinkStyle,
                        textTransform: "uppercase",
                        borderBottom:
                          underline === "with-us"
                            ? "4px solid #f38f1d"
                            : undefined
                      }}
                      className="header-link-underline"
                    >
                      {formatMessage({ id: "with-us" })}
                    </AniLink>
                  </li>
                  <li style={{ marginRight: "54px" }}>
                    <AniLink
                      cover
                      bg={`url(${
                        locale === "pt"
                          ? data.trasitionContactsPT.publicURL
                          : data.trasitionContactsEN.publicURL
                      })
                      center/25% no-repeat #f38f1d`}
                      duration={1}
                      direction="top"
                      to={`/${locale}/contact-us`}
                      style={{
                        ...headerLinkStyle,
                        textTransform: "uppercase",
                        borderBottom:
                          underline === "contact-us"
                            ? "4px solid #f38f1d"
                            : undefined
                      }}
                      className="header-link-underline"
                    >
                      {formatMessage({ id: "contact-us-top" })}
                    </AniLink>
                  </li>
                  <li>
                    <LanguageDropdown />
                  </li>
                </HeaderLinks>
              </Visible>
            </HeaderRow>
          </Col>
        </Row>
      </Container>
    </Header>
  );
};

TopMenu.defaultProps = {
  white: false,
  opacity: 1
};

export default TopMenu;
