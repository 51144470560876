import React, { FunctionComponent } from "react";
import "@reach/skip-nav/styles.css";

import StickyBox from "react-sticky-box";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";

import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";

import Footer from "./Footer";
import TopMenu from "./TopMenu";

import CookieConsentComponent from "../CookieConsent";
import Chat from "../Chat";

// @ts-ignore
import { theme, basic } from "../../styles";

import "typeface-source-sans-pro";
import { useStaticQuery, graphql } from "gatsby";

const globalStyle = css`
  ${basic}
  #___gatsby {
    width: 100vw;
    height: 100vh;
  }
  #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }
  .tl-edges {
    overflow-x: unset;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
`;

interface LayoutProps {
  footer?: boolean;
  topMenu?: boolean;
  whiteTopMenu?: boolean;
  topMenuOpacity?: number;
  stickyTopMenu?: boolean;
  metaName?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
  metaType?: string;
  metaImage?: {
    url: string;
    alt: string;
  };
  metaURL?: string;
  backgroundImageURL?: string;
  underline?: "about-us" | "with-us" | "contact-us";
}

const Layout: FunctionComponent<LayoutProps> = ({
  footer,
  topMenu,
  whiteTopMenu,
  stickyTopMenu,
  topMenuOpacity,
  metaName,
  metaTitle,
  metaDescription,
  metaKeywords,
  metaImage,
  metaType,
  metaURL,
  underline,
  backgroundImageURL,
  children,
}) => {
  const querydata: {
    image: {
      publicURL: string;
    };
  } = useStaticQuery(graphql`
    query {
      image: file(
        sourceInstanceName: { eq: "static" }
        relativePath: { eq: "images/white.jpg" }
      ) {
        publicURL
      }
    }
  `);
  const { locale } = useIntl();
  const localStyle = css`
    ${globalStyle}
    body {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-image: ${`url(${querydata.image.publicURL})`};
      ${backgroundImageURL && `background-image: url(${backgroundImageURL})`};
      transition: background-image 0.5s ease-in-out;
    }
  `;

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: "https://decunify.com",
    headline: "Decunify",
    inLanguage: "PT-PT",
    mainEntityOfPage: "https://decunify.com",
    description: metaDescription,
    name: metaTitle,
    author: {
      "@type": "Organization",
      name: "Mosano",
    },
    copyrightHolder: {
      "@type": "Organization",
      name: "DECUNIFY",
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      "@type": "Organization",
      name: "Decunify",
    },
    publisher: {
      "@type": "Organization",
      name: "Decunify",
    },
    dateModified: Date.now(),
    image: {
      "@type": "ImageObject",
      url: metaImage || "",
    },
  };

  const topMenuComponent = (
    <TopMenu
      white={whiteTopMenu}
      underline={underline}
      opacity={topMenuOpacity}
    />
  );
  return (
    <ThemeProvider theme={theme}>
      <Global styles={localStyle} />
      <Helmet htmlAttributes={{ lang: locale }}>
        <meta charSet="utf-8" />
        <title>{metaTitle || "Decunify"}</title>
        <meta
          name="description"
          content={metaDescription || "Decunify Website"}
        />
        <meta
          name="keywords"
          content={
            metaKeywords ||
            "Decunify, Soluções, Audio, Digital, Data Center, Infraestruturas"
          }
        />
        <meta name="image" content={metaImage?.url || ""} />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap"
          rel="stylesheet"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
        <script
          type="text/plain"
          data-category="analytics"
          src="https://cdn-eu.pagesense.io/js/decunify/f6384dc08a1f4c5794ff189081f2e5d6.js"
        />
      </Helmet>
      {/* Chat */}
      <Helmet>
        <script
          type="text/plain"
          data-category="analytics"
          src="../../chat.js"
          id="zsigchat"
        />
      </Helmet>
      {/* Facebook */}
      <Helmet>
        {metaName && <meta property="og:site_name" content={metaName} />}
        <meta property="og:locale" content={locale} />
        <meta property="og:url" content={metaURL} />
        <meta property="og:type" content={metaType || "website"} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage?.url || ""} />
        <meta
          property="og:image:alt"
          content={metaImage?.alt || metaDescription}
        />
      </Helmet>
      {/* Twitter */}
      <Helmet>
        {metaName && <meta name="twitter:creator" content={metaName} />}
        <meta name="twitter:card" content={metaType || "website"} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage?.url || ""} />
        <meta
          name="twitter:image:alt"
          content={metaImage?.alt || metaDescription}
        />
      </Helmet>
      {topMenu &&
        (stickyTopMenu ? (
          <StickyBox style={{ zIndex: 50 }}>{topMenuComponent}</StickyBox>
        ) : (
          topMenuComponent
        ))}
      <main style={{ flexGrow: 1 }}>{children}</main>
      <Chat></Chat>
      {footer && <Footer />}
      <noscript>
        <p>This page is best rendered on a browser that supports JavaScript!</p>
      </noscript>
      <CookieConsentComponent />
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  footer: true,
  topMenu: true,
  topMenuOpacity: 1,
  stickyTopMenu: true,
};

export default Layout;
