import styled from "@emotion/styled";

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Logo = styled.div`
  height: 51px;
  width: 241px;
  margin-top: 42px;
`;

export const SideMenuItems = styled.ul<{ white?: boolean }>`
  margin: 89px 0px 82px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;

  color: ${props => (props.white ? "white" : "black")};
  list-style-type: none;

  .sideList {
    padding: 0px 0px 48px 42px;
    position: relative;
    margin: 0;

    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;

      color: ${props => (props.white ? "black" : "white")};
      background: ${props => (props.white ? "black" : "white")};
      border: 2px solid ${props => (props.white ? "white" : "#f38f1d")};
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;

      content: "●";
      display: inline-block;
      text-align: center;
      line-height: 12px;
      font-size: 22px;

      transition: color 1s ease;
    }
    &.edge {
      &:after {
        line-height: 10px;
      }
    }
    &:before {
      position: absolute;
      left: 7px;
      content: "";
      width: 1px;
      height: 100%;
      border: solid 0.5px ${props => (props.white ? "white" : "black")};
      /* border: solid 0.5px #000000; */
    }
    &:last-of-type:before {
      border: none;
    }

    &.selected {
      &:after {
        color: ${(props: { theme?: any }) => props.theme.colors.decunifyOrange};
      }
    }
  }
`;
