import React, { FunctionComponent } from "react";
import { Visible } from "react-grid-system";
import { useIntl } from "react-intl";

// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";

import styles from "./styles";

interface iProps {
  footer?: boolean;
}

const NewsletterForm: FunctionComponent<iProps> = props => {
  const { Container } = styles;
  const { locale, formatMessage } = useIntl();
  const { footer } = props;

  return (
    <Container style={!footer ? { width: "80%" } : {}}>
      {footer && (
        <div className="news-letter-warning">
          <h2 className="subscrive">
            {formatMessage({ id: "subscribe-to" }) + "!"}
          </h2>
        </div>
      )}
      <form
        target="_blank"
        action="https://crm.zoho.eu/crm/WebToLeadForm"
        name="WebToLeads6990000000066159"
        method="POST"
      >
        <input
          type="text"
          style={{ display: "none" }}
          name="xnQsjsdp"
          defaultValue="1149a5b8633fa9280ae72dc67e0c48176c999898f1c8765513cec8fa589e14ee"
        />
        <input type="hidden" name="zc_gad" id="zc_gad" />
        <input
          type="text"
          style={{ display: "none" }}
          name="xmIwtLD"
          defaultValue="5e0fa7a9a05296632fa30b05766ad217b2d19a8bb17865740666a25be05875d1"
        />
        <input
          type="text"
          style={{ display: "none" }}
          name="actionType"
          defaultValue="TGVhZHM="
        />
        <input
          type="text"
          style={{ display: "none" }}
          name="returnURL"
          defaultValue="https://www.decunify.com"
        />
        <input
          type="text"
          style={{ display: "none" }}
          name="Lead Source"
          defaultValue="Formulário Web"
        />
        <input
          type="checkbox"
          style={{ display: "none" }}
          name="LEADCF101"
          defaultChecked={true}
        />
        <label
          id="labelForDataSource"
          htmlFor="data-source"
          className="visuallyhidden"
          style={{ display: "none " }}
        />
        <input
          aria-labelledby="labelForDataSource"
          type="hidden"
          value="Decunify Contact Form"
          name="data-source"
        />
        {footer ? (
          <div className="news-letter-row">
            <label
              id="labelForName"
              htmlFor="nameform"
              className="visuallyhidden"
              style={{ display: "none" }}
            >
              {formatMessage({ id: "name" })}
            </label>
            <input
              style={{ padding: "6px 10px" }}
              placeholder={formatMessage({ id: "name" })}
              className="input-form newsletter-input"
              aria-labelledby="labelForName"
              type="text"
              name="Last Name"
              id="nameform"
            />
            <label
              id="labelForCompany"
              htmlFor="companyform"
              className="visuallyhidden"
              style={{ display: "none" }}
            >
              {formatMessage({ id: "company" })}
            </label>
            <input
              aria-labelledby="labelForCompany"
              style={{ padding: "6px 10px" }}
              placeholder={formatMessage({ id: "company" })}
              className="input-form newsletter-input"
              type="text"
              name="Company"
            />
          </div>
        ) : (
          <>
            <div className="news-letter-row">
              <label
                id="labelForName"
                htmlFor="nameform"
                className="visuallyhidden"
                style={{ display: "none" }}
              >
                {formatMessage({ id: "name" })}
              </label>
              <input
                style={{ padding: "6px 10px" }}
                placeholder={formatMessage({ id: "name" })}
                className="input-form newsletter-input"
                aria-labelledby="labelForName"
                type="text"
                name="Last Name"
                id="nameform"
              />
            </div>
            <div className="news-letter-row">
              <label
                id="labelForCompany"
                htmlFor="companyform"
                className="visuallyhidden"
                style={{ display: "none" }}
              >
                {formatMessage({ id: "company" })}
              </label>
              <input
                aria-labelledby="labelForCompany"
                style={{ padding: "6px 10px" }}
                placeholder={formatMessage({ id: "company" })}
                className="input-form newsletter-input"
                type="text"
                name="Company"
              />
            </div>
          </>
        )}
        <div className="news-letter-row">
          <label
            id="labelForEmail"
            htmlFor="emailform"
            className="visuallyhidden"
            style={{ display: "none" }}
          >
            {formatMessage({ id: "email" })}
          </label>
          <input
            aria-labelledby="labelForEmail"
            style={{ padding: "6px 10px" }}
            placeholder="example@email.com"
            className="input-form newsletter-input"
            type="email"
            name="Email"
            id="emailform"
          />
          <button
            type="submit"
            className="button-news-letter"
            style={!footer ? { width: "15%" } : {}}
          >
            {formatMessage({ id: "send" })}
          </button>
        </div>
        <div className="policy-row">
          <Visible xs sm>
            <h3 className="policy-warning" style={{ width: "100%" }}>
              {formatMessage({ id: "footer-subscribe-to-terms" })}
              <AniLink
                cover
                bg="#f38f1d"
                duration={1}
                direction="top"
                className="policy-news-link"
                to={`/${locale}/policies#privacy`}
              >
                {formatMessage({ id: "privacy-policy" })}
              </AniLink>
            </h3>
          </Visible>
          <Visible md lg xl>
            <h3 className="policy-warning">
              {formatMessage({ id: "footer-subscribe-to-terms" })}
              <AniLink
                cover
                bg="#f38f1d"
                duration={1}
                direction="top"
                className="policy-news-link"
                to={`/${locale}/policies#privacy`}
              >
                {formatMessage({ id: "privacy-policy" })}
              </AniLink>
            </h3>
          </Visible>
        </div>
      </form>
    </Container>
  );
};

export default NewsletterForm;
