import styled from "@emotion/styled";

const Header = styled.header<{ opacity?: number }>`
  width: 100%;
  padding: 42px 0;
  transition: background-color 1000ms linear;
  background-color: rgba(
    255,
    255,
    255,
    ${props => (props.opacity !== undefined ? props.opacity : 1)}
  );
`;

const HeaderLogo = styled.img`
  width: 241px;
  height: 51px;
  object-fit: contain;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const HeaderLinks = styled.ul`
  margin: 0;
  padding: 0;
  li {
    display: inline;
  }
`;

const MobileHeader = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 50;
  background-color: white;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MobileBackground = styled.div`
  width: 84.3%;
  height: 100%;
  padding-top: 15px;
  padding-left: 15px;
  background-color: #e69040;
`;

const MenuDivider = styled.hr`
  width: 100%;
  height: 1px;
  opacity: 0.3;
  margin: 10px 0 18px 0;
  background-color: #ffffff;
`;

const styles = {
  Header,
  HeaderLogo,
  HeaderRow,
  HeaderLinks,
  MobileHeader,
  MobileBackground,
  MenuDivider
};

export default styles;
