import React, { FunctionComponent } from "react";

import {
  faTwitter,
  faFacebookF,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles";

interface SocialNavProps {
  white?: boolean;
}

const SocialNav: FunctionComponent<SocialNavProps> = ({ white }) => {
  const { SocialLinksRow } = styles;

  return (
    <SocialLinksRow white={white}>
      <a href="https://www.facebook.com/decunify" aria-label="Facebook">
        <FontAwesomeIcon
          size="lg"
          icon={faFacebookF}
          color={white ? "white" : "black"}
        />
      </a>
      <a href="https://pt.linkedin.com/company/decunify-sa" aria-label="LinkedIn">
        <FontAwesomeIcon
          size="lg"
          icon={faLinkedinIn}
          color={white ? "white" : "black"}
        />
      </a>
      <a href="https://twitter.com/decunify" aria-label="Twitter">
        <FontAwesomeIcon
          size="lg"
          icon={faTwitter}
          color={white ? "white" : "black"}
        />
      </a>
      <a href="#share" aria-label="Share">
        <FontAwesomeIcon
          size="lg"
          icon={faShareAlt}
          color={white ? "white" : "black"}
        />
      </a>
    </SocialLinksRow>
  );
};

export default SocialNav;
