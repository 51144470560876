import React from "react";
import "../../chat.css";

const Chat = () => (
  <>
    <div id="zsiqchat">
      <div id="zsiqwidget"></div>
    </div>
  </>
);

export default Chat;
