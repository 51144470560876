import styled from "@emotion/styled";

const FooterWrapper = styled.footer`
  padding: 50px 0;
  background-color: #4d4d4d;
`;

const FooterImg = styled.div`
  width: 85px;
  height: 87px;
  margin-bottom: 30px;
`;

const FooterLinks = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  li {
    display: inline;
  }
`;

const FooterRights = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

const styles = {
  FooterWrapper,
  FooterImg,
  FooterLinks,
  FooterRights
};

export default styles;
