import styled from "@emotion/styled";

const Container = styled.div<{ footer?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${props =>
    props.footer &&
    `
    background-color: #4d4d4d;
  `}

  input {
    background-color: #fff;
  }
`;

const styles = {
  Container
};
export default styles;
