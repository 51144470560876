import React from "react";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

const IS_BROWSER = typeof window !== "undefined";

const Preview = (WrappedPage: any) => ({ data, ...rest }: any) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;

  const mergedData = mergePrismicPreviewData({
    staticData: data,
    previewData
  });

  return <WrappedPage {...rest} data={mergedData} />;
};

export default Preview;
