import { css } from "@emotion/core";
import theme from "./theme";

const basic = css`
.text-info-s{
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 46px;
  margin-bottom: 9px;
  display: block;
}
.info-s{
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: block;
  margin-top: 4px;
  margin-bottom: 5px;
}
.button-s{
  border: none;
  outline: none;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  min-height: 32px;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  font-family: Roboto;
  font-stretch: normal;
  letter-spacing: normal;
  border: 2px solid #777777;
  background-color: #ffffff;
  transition: all 0.15s ease-in-out;
}
.input-s{
  width: 100%;
  outline: none;
  display: block;
  max-width: 185px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  font-style: normal;
  border-radius: 3px;
  margin-bottom: 3px;
  font-family: Roboto;
  font-stretch: normal;
  box-sizing: border-box;
  letter-spacing: normal;
  background-color: #ffffff;
  border: 2px solid #777777;
  padding: 6px 11px 9px 11px;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ::placeholder {
    color: #d4cfcf;
  }
}
.btn {
  outline: none;
  color: #f38f1d;
  font-size: 14px;
  cursor: pointer;
  min-height: 45px;
  font-weight: 400;
  padding: 16px 16px;
  font-style: normal;
  line-height: normal;
  font-family: Roboto;
  font-stretch: normal;
  letter-spacing: 1.5px;
  text-decoration: none !important;
  background-color: white;
  border: 1px solid #f38f1d;
  text-transform: uppercase;
  transition: all 0.15s ease-in-out;
}

.btn:hover {
  color: white;
  background-color: #f38f1d;
}

.transparent {
  background-color: rgba(100, 100, 100, 0.6);
  color: white;
  border-color: white;
}
  .Arrow-Button{
    width:40px
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  .banner-mobile {
    @media (min-width: 782px) {
      display:none;
    }
  }
  .banner-desktop {
    @media (max-width: 782px) {
      display:none;
    }
  }
  .container{
    display: flex;
    flex-direction: column;
    margin-left:auto;
    margin-right:auto;
    padding-left:15px;
    padding-right:15px;
    max-width:1200px;
  }
  .contact-support-link{
    color: white;
  }
  .contact-support-link:hover {
    text-decoration-color: white;
  }
  .blog-title-link:hover{
    text-decoration: none;
  }
  .header-link-underline:hover {
    border-bottom: 4px solid #f38f1d;
    transition-timing-function: ease-in;
    transition: 0.1s;
  }
  .case-study-back-button {
    margin-top: 131px;
    width: 316px;
    height: 45px;
    border: solid 1px #f38f1d;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
  }
  .download-text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .extra {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .case-study-extra {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
  }
  .pdf-link:hover {
    text-decoration: none;
  }
  .pdf-info {
    width: 45px;
    height: 56px;
  }
  .solution-img-1 {
    padding: 12px;
    max-width: 776px;
    max-height: 527px;
  }
  .solution-img-2 {
    padding: 12px;
    max-width: 376px;
    max-height: 527px;
  }
  .case-study-solution-img {
    display: flex;
    justify-content: center;
  }
  .case-study-solution {
    margin-top: 94px;
    padding-left: 132px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .case-study-challenge-img {
    max-width: 706px;
    max-height: 441px;
    object-fit: cover;
    margin-top: 71px;
  }
  .case-study-challenge {
    margin-top: 97px;
    padding-left: 132px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .line-client {
    background-color: #f38f1d;
    width: 276px;
    height: 2px;
    border-radius: 5px;
    left: -50%;
    transform: translateX(-50%);
  }
  .line-solution {
    background-color: #f38f1d;
    width: 325px;
    height: 2px;
    border-radius: 5px;
    left: -50%;
    transform: translateX(-50%);
  }
  .line-challenge {
    background-color: #f38f1d;
    width: 300px;
    height: 2px;
    border-radius: 5px;
    left: -50%;
    transform: translateX(-50%);
  }
  .case-study-result {
    margin-top: 94px;
    padding-left: 132px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .case-study-client {
    padding-left: 132px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .case-study-client-info {
    display: flex;
    flex: 1;
  }
  .case-study-result-desc {
    display: flex;
    max-width: 976px;
  }
  .case-study-challenge-desc {
    display: flex;
    max-width: 976px;
  }
  .case-study-solution-desc {
    display: flex;
    max-width: 976px;
  }
  .case-study-image {
    padding-left: 26px;
    flex: 0 1 50%;
    max-height: 50vh;
    object-fit: cover;
  }
  .case-study-client-title {
    font-family: Roboto;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 42px;
  }
  .case-study-client-desc {
    flex: 1 0 50%;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #000000;
  }
  .case-study-post-intro {
    padding-left: 132px;
    width: 60%;
    margin-top: 97px;
    padding-bottom: 95px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #000000;
  }
  .case-study-post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .case-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .case-line-4 {
    border: solid 1px #f38f1d;
  }
  .case-title {
    font-family: Roboto;
    font-size: 42px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .case-subTitle {
    font-family: Roboto;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .case-study-cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
  .case-study-link:hover {
    text-decoration: none;
  }
  .share-social {
    height: 100%;
    width: 200px;
  }
  .blog-post-wsiwyg {
    padding-top: 55px;
  }
  .follow-next {
    width: 100%;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 28px;
  }
  .blog-next-post {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .blog-post-tags {
    margin-top: 35px;
  }
  .blog-post-tags_row {
    margin-top: 25px;
  }
  .blog-intro-content {
    position: relative;
  }
  .rec-orange-post {
    position: absolute;
    top: -11px;
    left: -11px;
    width: 68px;
    height: 218px;
    background-color: #f38f1d;
    z-index: 0;
  }
  .post-image__blog {
    width: 100%;
    height: 50vh;
    position: relative;
    z-index: 3;
    padding-top: 10px;
    padding-bottom: 20px;
    object-fit: cover;
  }
  .blog-post-date {
    width: 240px;
    border-top: solid 1px #979797;
  }
  .post-sub-title__blog {
    width: 60%;
    font-family: Roboto;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
  }
  .post-title__blog {
    font-family: Roboto;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
  }
  .post-tag-0 {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .post-tag {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding: 5px;
  }
  .blog-row {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .blog-post {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
  }
  .read-more-arrow {
    height: 13px;
    padding-left: 10px;
  }
  .social-bar {
    padding-top: 15px;
    display: flex;
    border-top: 1px solid black;
  }
  .social-icon {
    padding: 5px;
    height: auto;
    width: 36px;
  }
  .sideList-menu {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    margin-left: 14px;
  }
  .blog-category {
    padding: 5px;
    color: #000000;
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .blog-index {
    padding-bottom: 50px;
  }
  .sideList-title {
    margin-left: 14px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .policy-news-link {
    color: white;
    text-decoration: underline;
    font-style: normal;
  }
  .policy-row {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 5px;
  }
  .policy-warning {
    width: 50%;
    color: white;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: right;
  }
  .button-news-letter {
    margin: 5px;
    background-color: #f38f1d;
    color: white;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    cursor: pointer;
  }
  .button-news-letter:hover {
    background-color: rgba(243, 143, 29, 0.8);
  }
  .newsletter-input {
    margin: 5px;
  }
  .news-letter-warning {
  }
  .news-letter-form {
    display: flex;
    flex-direction: column;
  }
  .news-letter-row {
    display: flex;
  }
  .subscrive {
    color: white;
    text-transform: uppercase;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }
  .iso9001-logo {
    with: 100px;
    height: 100px;
    object-fit: cover;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
  }
  .separator {
    color: white;
  }
  .copy-right {
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
    color: white;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
  }
  .privacy-cookies {
    text-decoration: underline;
    color: white;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
  }
  .blog-page-link {
    color: #f38f1d;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
  }
  .blog-page-link:hover {
    text-decoration: none;
  }
  .view-all {
    cursor: pointer;
    margin-top: 80px;
    width: 176px;
    height: 45px;
    border: solid 1px #f38f1d;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .view-all:hover {
    text-decoration: none;
  }
  .view-all:hover {
    .blog-page-link {
      color: white;
    }
    transition-timing-function: ease-in;
    background-color: #f38f1d;
    transition: 0.1s;
  }
  .post-date {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #a7a9ac;
  }
  .post-link {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: right;
    color: #000000;
  }
  .know-more-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .know-more-col {
    display: flex;
    flex-direction: column;
  }
  .upper-case {
    text-transform: uppercase;
  }
  .blog-title {
    font-family: Roboto;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.75px;
    text-align: center;
    color: #f38f1d;
    margin-bottom: 42px;
    text-decoration: none;
  }
  .row__title {
    text-align: center;
  }
  .message-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
  .decunify-form {
    max-width: 750px;
    display: flex;
    flex-direction: column;
  }
  .input-form {
    height: 36px;
    border: solid 1px #ffffff;
    background-color: rgba(216, 216, 216, 0.7);
    border: solid 1px #ffffff;
    font-family: Roboto;
    width: 100%;
  }
  .buttonLink {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
  .service-info {
    margin-top: 50px;
  }
  .service-title {
    width: 371px;
    font-family: Roboto;
    font-size: 42px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: right;
    margin: 0px;
  }
  .service-subtitle {
    width: 371px;
    font-family: Roboto;
    font-size: 42px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: right;
    color: ${theme.colors.decunifyOrange};
  }
  .service-info-text {
    width: 371px;
    height: 185px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
  .image-losange {
    width: 200px;
    height: 200px;
  }
  .service-1 {
    height: 200px;
    width: 200px;
    cursor: pointer;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  .service-2 {
    height: 200px;
    width: 200px;
    cursor: pointer;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  .service-3 {
    height: 200px;
    width: 200px;
    cursor: pointer;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  .service-1:hover {
    -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0);
  }
  .service-2:hover {
    -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0);
  }
  .service-3:hover {
    -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0);
  }
  .row {
    display: flex;
  }
  .arrows {
    width: 90%;
    flex-directon: row;
    justify-content: space-between;
    align-items: center;
  }
  .line {
    width: 375px;
    height: 1px;
    border: solid 1px #f38f1d;
  }
  .line-post {
    width: 100%;
    border: solid 1px #979797;
  }
  .client-success {
    font-family: Roboto;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .client-sucess-description {
    font-family: Roboto;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .blog-post-image {
    position: relative;
    width: 276px;
    height: 253px;
    object-fit: cover;
  }
  .blog-post-title {
    display: flex;
    margin-top: 32px;
    width: 240px;
    height: 78px;
  }
  .post-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
  }
  .blog-post-intro {
    margin-top: 25px;
    margin-bottom: 15px;
    width: 240px;
    height: 78px;
  }
  .post-intro {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
  }
  .post-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 3;
  }
  .rec0 {
    position: absolute;
    top: -11px;
    left: -11px;
    width: 51px;
    height: 218px;
    background-color: #f38f1d;
  }
  .rec1 {
    position: absolute;
    bottom: -11px;
    left: -11px;
    width: 193px;
    height: 134px;
    background-color: #f38f1d;
  }
  .rec2 {
    position: absolute;
    bottom: -11px;
    right: -11px;
    width: 151px;
    height: 138px;
    background-color: #f38f1d;
  }
  .about-us-images {
    position: relative;
    margin-bottom: 140px;

    @media (max-width: 1024px) {
      margin-bottom: 50px;
    }
  }
  .about-us-pdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 140px;

    @media (max-width: 1024px) {
      margin-bottom: 50px;
    }
  }
  .pdf-icons {
    width: 45px
    height: 56px;

    @media (max-width: 450px) {
      width: 33px;
      height: 41px;
    }
  }
`;

export default basic;
