import styled from "@emotion/styled";

const SocialLinksRow = styled.div<{ white?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 175px;
  padding: 16px 28px 64px 28px;
  border-top: 1px solid ${props => (props.white ? "white" : "black")};
`;

const styles = { SocialLinksRow };

export default styles;
