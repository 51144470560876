const theme = {
  colors: {
    primary: "#3D63AE",
    white: "#fff",
    black: "#000",
    greyLight: "#EBEDF2",
    grey: "#595C62",
    greyDarker: "#1c252b",
    decunifyOrange: "#f38f1d",
    playGrey: "#bababa",
    playGrey2: "#979797"
  },
  maxWidth: "1000px",
  maxWidthText: "720px",
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px"
  }
};

export default theme;
