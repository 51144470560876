import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";

import SocialNav from "../SocialNav";
import { Menu, Logo, SideMenuItems } from "./styledComponents";
export enum SelectionOptions {
  SOLUTIONS,
  SERVICES,
  SUCCESSES,
  BLOG,
  CONTACTS
}

interface SideMenuProps {
  white?: boolean;
  blog?: boolean;
  tags?: any[];
  social?: {
    data: {
      name?: string;
      image: {
        url: string;
        alt: string;
      };
    };
  };
  scrollhandler?(input: SelectionOptions): void;
  selectedOption?: SelectionOptions;
}

const SideMenu: FunctionComponent<SideMenuProps> = props => {
  const { formatMessage } = useIntl();
  return (
    <Menu>
      <Logo />
      <SideMenuItems white={props.white}>
        {props.blog ? (
          <>
            <h2 className="sideList-title upper-case">
              {formatMessage({ id: "categories" })}
            </h2>
            <div className="sideList-menu">
              {props?.tags?.map((tag, index) => (
                <a href="#" className="blog-category" key={`${tag}-${index}`}>
                  {" "}
                  {tag}{" "}
                </a>
              ))}
            </div>
          </>
        ) : (
          <>
            <li
              style={{ textTransform: "uppercase" }}
              className={classnames("sideList", {
                selected: props.selectedOption === SelectionOptions.SOLUTIONS,
                edge:
                  typeof window !== "undefined" &&
                  window?.navigator &&
                  window?.navigator?.userAgent &&
                  window?.navigator?.userAgent.indexOf("Edge") > -1
              })}
              onClick={() => {
                if (props?.scrollhandler) {
                  props.scrollhandler(SelectionOptions.SOLUTIONS);
                }
              }}
            >
              {formatMessage({ id: "solutions" })}
            </li>
            <li
              style={{ textTransform: "uppercase" }}
              className={classnames("sideList", {
                selected: props.selectedOption === SelectionOptions.SERVICES,
                edge:
                  typeof window !== "undefined" &&
                  window?.navigator &&
                  window?.navigator?.userAgent &&
                  window?.navigator?.userAgent.indexOf("Edge") > -1
              })}
              onClick={() => {
                if (props?.scrollhandler) {
                  props.scrollhandler(SelectionOptions.SERVICES);
                }
              }}
            >
              {formatMessage({ id: "services" })}
            </li>
            <li
              style={{ textTransform: "uppercase" }}
              className={classnames("sideList", {
                selected: props.selectedOption === SelectionOptions.SUCCESSES,
                edge:
                  typeof window !== "undefined" &&
                  window?.navigator &&
                  window?.navigator?.userAgent &&
                  window?.navigator?.userAgent.indexOf("Edge") > -1
              })}
              onClick={() => {
                if (props?.scrollhandler) {
                  props.scrollhandler(SelectionOptions.SUCCESSES);
                }
              }}
            >
              {formatMessage({ id: "case-studies" })}
            </li>
            <li
              style={{ textTransform: "uppercase" }}
              className={classnames("sideList", {
                selected: props.selectedOption === SelectionOptions.BLOG,
                edge:
                  typeof window !== "undefined" &&
                  window?.navigator &&
                  window?.navigator?.userAgent &&
                  window?.navigator?.userAgent.indexOf("Edge") > -1
              })}
              onClick={() => {
                if (props?.scrollhandler) {
                  props.scrollhandler(SelectionOptions.BLOG);
                }
              }}
            >
              {formatMessage({ id: "blog" })}
            </li>
          </>
        )}
      </SideMenuItems>
      <SocialNav white={props.white} />
    </Menu>
  );
};

export default SideMenu;
